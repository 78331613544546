<template>
  <b-card
    class="statementitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="statementitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单ID"
            label-for="statement_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_id"
              size="sm"
              v-model="statementitem.statement_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="对账单编号"
            label-for="statement_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="statement_no"
              size="sm"
              v-model="statementitem.statement_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单ID"
            label-for="order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_id"
              size="sm"
              v-model="statementitem.order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售单编号"
            label-for="order_no"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="order_no"
              size="sm"
              v-model="statementitem.order_no"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售员ID"
            label-for="sales_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_id"
              size="sm"
              v-model="statementitem.sales_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售员名称"
            label-for="sales_name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_name"
              size="sm"
              v-model="statementitem.sales_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售数量"
            label-for="total_qty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_qty"
              size="sm"
              v-model="statementitem.total_qty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="销售金额"
            label-for="total_amount"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="total_amount"
              size="sm"
              v-model="statementitem.total_amount"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="售卖日期"
            label-for="sales_time"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="sales_time"
              size="sm"
              v-model="statementitem.sales_time"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="状态"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="statementitem.state"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import statementitemStore from './statementitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      statementitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('statementitem')) store.registerModule('statementitem', statementitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('statementitem')) store.unregisterModule('statementitem')
    })

    const edit = function() {
      store.dispatch('statementitem/edit', {id: this.id}).then(res => {
        this.statementitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('statementitem/view', {id: this.id}).then(res => {
        this.statementitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('statementitem/save', this.statementitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>